import { useCallback, useState } from 'react';
import { CircularProgress, LinearProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { enqueueSnackbar } from 'notistack';
import { flushSync } from 'react-dom';
import { Box, CloseIcon, IconButton, Typography } from '@/components/common';
import {
  ingestBatchMedia,
  MediaIngestionDTO,
  mutateMediasOfAlbum,
  preUploadMedia,
} from '@/api/meero-suite/media';
import { createAlbum } from '@/api/meero-suite/album';
import { FormInputText } from '@/components/common/FormInputs';
import { ButtonMeero } from '@/components/common/ButtonMeero/ButtonMeero';
import { useCurrentMsUser } from '@/api/meero-suite/users';
import { StyledMobileStepper } from '@/components/common/GuidelinesDialog/GuidelineDialog.styles';
import { trackButtonClicked, trackIndependentEvent } from '@/lib/analytics';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';
import { Album } from '@/types/Album/Album';
import { createDateFormatter } from '@/utils/date-formatter';
import { Sky } from '@/api/computer-vision/sky-replacement';
import { SelectPhotos, UploadFileRequest } from './SelectPhotos/SelectPhotos';
import {
  DialogFooter,
  DialogLayout,
  StatusBarContainer,
  StyledImportDialog,
  TitleContainer,
} from './ImportPhotosDialog.styles';
import { SkyReplacement } from './SkyReplacement/SkyReplacement';
import { UploadStatus } from './SelectPhotos/SelectPhotos.styles';

type UploadPhotosDialogProps = {
  currentTabId?: 'media' | 'albums';
  isOpen: boolean;
  onClose: () => void;
  targetAlbum?: Album;
};

type StepId = 'select-photos' | 'sky-replacement' | 'finalize-import';
const idxToStep: Record<number, StepId> = {
  0: 'select-photos',
  1: 'sky-replacement',
  2: 'finalize-import',
};

type UploadStep =
  | 'initial'
  | 'uploading-files'
  | 'creating-album'
  | 'ingesting-media'
  | 'completed'
  | 'error';

export function ImportPhotosDialog({
  currentTabId,
  isOpen,
  onClose,
  targetAlbum,
}: UploadPhotosDialogProps) {
  const router = useRouter();
  const { t } = useTranslation('importPhotos');
  const { msUser } = useCurrentMsUser();
  const { workspaceId, getTokenAsync } = useAuth0Credentials();

  const dateFormatter = createDateFormatter(
    'month-day-two-digit',
    router.locale
  );

  const [albumName, setAlbumName] = useState('');
  const [selectedSky, setSelectedSky] = useState<Sky>();
  const [dialogStepIdx, setDialogStepIdx] = useState(0);
  const [uploadStep, setUploadStep] = useState<UploadStep>('initial');

  const [uploadRequests, setUploadRequests] = useState<UploadFileRequest[]>([]);

  const isNewAlbum = targetAlbum === undefined;
  const isLoading = uploadStep !== 'initial' && uploadStep !== 'error';

  const setRequestStatus = useCallback(
    (index: number, status: UploadStatus, destinationKey?: string) => {
      setUploadRequests((prevRequests) => {
        const newRequests = [...prevRequests];
        newRequests[index].uploadStatus = status;
        newRequests[index].destinationKey = destinationKey ?? null;

        return newRequests;
      });
    },
    []
  );

  const onUploadFiles = async () => {
    if (uploadStep !== 'initial') {
      return;
    }

    setUploadStep('uploading-files');
    for (let i = 0; i < uploadRequests.length; i++) {
      try {
        setRequestStatus(i, 'in-progress');
        const res = await preUploadMedia(
          uploadRequests[i].file,
          getTokenAsync,
          workspaceId
        );
        /**
         * Without flushSync, the status update was not always taken into account before moving to the next step
         * It was causing a bug when adding pictures to an existing album:
         * Some pictures were still in the "in-progress" state when starting the step 'ingesting-media'
         */
        flushSync(() => setRequestStatus(i, 'completed', res.destinationKey));
      } catch (e) {
        console.error(e);
        setRequestStatus(i, 'error');
      }
    }

    const completedRequests = uploadRequests.filter(
      (req) => req.uploadStatus === 'completed'
    );

    let destinationAlbum: Album;
    // Create the album if an existing album is not provided
    if (isNewAlbum) {
      setUploadStep('creating-album');
      const createdAlbum = await createAlbum(
        albumName.length === 0
          ? t('defaultAlbumName', {
              date: dateFormatter.format(new Date()),
            })
          : albumName,
        getTokenAsync,
        workspaceId
      ).catch((e) => {
        throw new Error('Error while creating the album', { cause: e });
      });

      trackIndependentEvent('Album Created', {
        album_uuid: createdAlbum.uuid,
        media_number: completedRequests.length,
        album_source: 'web',
      });
      destinationAlbum = createdAlbum;
    } else {
      destinationAlbum = targetAlbum;
    }

    setUploadStep('ingesting-media');
    const ingestionRequests = completedRequests.map((request) => {
      const mediaIngestion: MediaIngestionDTO = {
        ingestionData: {
          accountUuid: workspaceId ?? 'missing_account_uuid',
          albumReference: destinationAlbum.reference,
          mediaReference: crypto.randomUUID(),
          origin: 'web',
          originalName: request.file.name,
          workflowUuid: msUser?.defaultWorkflowUuid ?? 'missing_workflow_uuid',
          editingParameters:
            selectedSky !== undefined ? [`sky:${selectedSky.id}`] : [],
        },
        location: {
          path: request.destinationKey ?? '',
          source: 's3',
        },
      };

      return mediaIngestion;
    });

    await ingestBatchMedia(ingestionRequests, getTokenAsync, workspaceId).catch(
      (e) => {
        throw new Error('Error while ingesting media', { cause: e });
      }
    );
    setUploadStep('completed');

    // Wait around 10 seconds to let the backend process the ingestion
    await new Promise((resolve) => setTimeout(resolve, 10000)).then(() => {
      // If updating an existing album, we refresh the album's media list
      if (!isNewAlbum) {
        mutateMediasOfAlbum(destinationAlbum.uuid);
      }
      void router.push(`/library/albums/${destinationAlbum.uuid}?page=1`);
    });

    onClose();
  };

  function handlePrevious() {
    setDialogStepIdx((prevIdx) => prevIdx - 1);
  }

  const disableNextButton =
    (dialogStepIdx === 0 && uploadRequests.length === 0) ||
    uploadStep !== 'initial';

  const disableBackButton = dialogStepIdx === 0 || uploadStep !== 'initial';

  function handleNext() {
    if (dialogStepIdx === 0 && uploadRequests.length === 0) {
      return;
    }

    if (dialogStepIdx === 2) {
      trackButtonClicked('Enhance My Photo CTA Clicked', {
        page:
          currentTabId === 'albums' ? 'Library_Albums' : 'Library_All Media',
        media_number: uploadRequests.length,
        sky_replacement: selectedSky !== undefined ? 1 : 0,
        sky_replacement_option: [
          selectedSky?.id,
          selectedSky?.skyType,
          selectedSky?.timeOfDay,
        ].join(','),
        album_source: 'web',
      });
      onUploadFiles().catch((e) => {
        console.error(e);
        setUploadStep('error');
        enqueueSnackbar(t('errors.generic'), {
          variant: 'error',
          preventDuplicate: true,
        });
      });

      return;
    }

    setDialogStepIdx((prevIdx) => prevIdx + 1);
  }

  const submitButtonLabel = isNewAlbum
    ? t('submitCreateAlbum')
    : t('submitAddPhotos');

  return (
    <StyledImportDialog open={isOpen} maxWidth="md" fullWidth>
      <DialogLayout>
        <TitleContainer>
          <Typography component="span" variant="h5" fontWeight="bold">
            <Typography
              component="span"
              variant="inherit"
              color="primary"
              marginRight={1}
            >
              {`${dialogStepIdx + 1}/3`}
            </Typography>
            {t(`steps.${idxToStep[dialogStepIdx]}`)}
          </Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TitleContainer>
        {dialogStepIdx === 0 && isNewAlbum && (
          <FormInputText
            id="name"
            type="text"
            value={albumName}
            updateValue={(val) => setAlbumName(val)}
            placeHolder={t('albumName')}
          />
        )}
        <Box
          flexGrow={1}
          overflow="hidden"
          width="100%"
          sx={{ display: dialogStepIdx === 1 ? 'none' : 'flex' }}
        >
          <SelectPhotos
            uploadRequests={uploadRequests}
            setUploadRequests={setUploadRequests}
            showUploadStatus={dialogStepIdx === 2}
            readonly={dialogStepIdx === 2}
          />
        </Box>
        {dialogStepIdx === 1 && (
          <SkyReplacement
            selectedSky={selectedSky}
            setSelectedSky={setSelectedSky}
          />
        )}
        {dialogStepIdx === 2 && (
          <UploadStatusBar
            uploadStep={uploadStep}
            uploadRequests={uploadRequests}
            submitButtonLabel={submitButtonLabel}
          />
        )}
        <DialogFooter>
          <StyledMobileStepper
            position="static"
            activeStep={dialogStepIdx}
            steps={3}
            backButton={undefined}
            nextButton={undefined}
          />
          <Box display="inline-flex" columnGap={1}>
            {dialogStepIdx > 0 && (
              <ButtonMeero
                $type="secondary"
                disabled={disableBackButton}
                onClick={handlePrevious}
              >
                {t('back')}
              </ButtonMeero>
            )}
            <ButtonMeero disabled={disableNextButton} onClick={handleNext}>
              <Typography
                variant="inherit"
                visibility={isLoading ? 'hidden' : 'unset'}
              >
                {dialogStepIdx === 2 ? submitButtonLabel : t('next')}
              </Typography>
              {isLoading && (
                <CircularProgress
                  size={18}
                  sx={{
                    position: 'absolute',
                    color: 'white',
                    left: 0,
                    right: 0,
                    margin: 'auto',
                  }}
                />
              )}
            </ButtonMeero>
          </Box>
        </DialogFooter>
      </DialogLayout>
    </StyledImportDialog>
  );
}

type UploadStatusBarProps = {
  uploadStep: UploadStep;
  uploadRequests: UploadFileRequest[];
  submitButtonLabel: string;
};
function UploadStatusBar({
  uploadStep,
  uploadRequests,
  submitButtonLabel,
}: UploadStatusBarProps) {
  const { t } = useTranslation('importPhotos');

  const completedCount = uploadRequests.filter(
    (request) => request.uploadStatus === 'completed'
  ).length;

  const progress =
    uploadRequests.length !== 0
      ? (completedCount / uploadRequests.length) * 100
      : 0;

  return (
    <StatusBarContainer>
      <Typography fontWeight="bold" textAlign="left">
        {t(`uploadStep.${uploadStep}`, {
          completedCount,
          total: uploadRequests.length,
          submitButtonLabel,
        })}
      </Typography>
      <Box paddingBottom={1}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ height: 10, borderRadius: 4 }}
        />
      </Box>
    </StatusBarContainer>
  );
}
