import { alpha } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';
import { AsYouType } from 'libphonenumber-js/min';
import { useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@/components/common';
import { colors, fontSize } from '@/theme';
import styled from '@/utils/styled';

export type FormInputTextProps = {
  id: string;
  type: 'text' | 'email' | 'tel' | 'password' | 'number';
  variant?: 'textInput' | 'inputWithLabel' | 'inputWithIcon';
  label?: string;
  placeHolder?: string;
  errorMessage?: string;
  value?: string;
  updateValue?: (value: string) => void;
  onFieldError?: () => void;
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  textFieldRef?: React.Ref<unknown>;
  inputRef?: React.Ref<unknown>;
  inputBaseProps?: InputBaseProps;
  disabled?: boolean;
};

export const FormInputText = ({
  id,
  variant = 'textInput',
  type = 'text',
  label,
  placeHolder,
  errorMessage,
  value,
  updateValue,
  fullWidth = true,
  required = false,
  error = false,
  disabled = false,
  textFieldRef,
  inputBaseProps,
  inputRef,
}: FormInputTextProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const helperTextId =
    errorMessage !== undefined ? `${id}-helper-text` : undefined;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const displayLabelFromInput =
    variant === 'textInput' || variant === 'inputWithIcon';
  const isShrinkInput = variant === 'inputWithIcon' ? undefined : true;

  return (
    <FormControl
      variant={variant === 'inputWithIcon' ? 'outlined' : 'standard'}
      fullWidth={fullWidth}
      required={required}
      error={error}
    >
      {displayLabelFromInput && label !== undefined && (
        <StyledInputLabel
          $variant={variant}
          htmlFor={id}
          shrink={isShrinkInput}
          required={required}
        >
          {label}
        </StyledInputLabel>
      )}

      {variant === 'textInput' && (
        <TextInput
          id={id}
          type={type}
          ref={textFieldRef}
          inputRef={inputRef}
          title={label}
          placeholder={placeHolder}
          value={value}
          disabled={disabled}
          onChange={(event) => updateValue?.(event.target.value)}
          fullWidth={fullWidth}
          required={required}
          aria-describedby={helperTextId}
          error={error}
          {...inputBaseProps}
        />
      )}
      {variant === 'inputWithLabel' && (
        <TextField
          id={id}
          type={type}
          title={label}
          label={label}
          placeholder={placeHolder}
          value={value}
          disabled={disabled}
          onChange={(event) => updateValue?.(event.target.value)}
          fullWidth={fullWidth}
          required={required}
          error={error}
        />
      )}
      {variant === 'inputWithIcon' && (
        <OutlinedInput
          id={id}
          type={showPassword ? 'text' : 'password'}
          title={label}
          onChange={(event) => updateValue?.(event.target.value)}
          value={value}
          required={required}
          aria-describedby={helperTextId}
          error={error}
          style={{ overflow: 'hidden' }}
          endAdornment={
            <InputAdornment position="end">
              <StyledIconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </StyledIconButton>
            </InputAdornment>
          }
          label={label}
        />
      )}
      {error && errorMessage !== undefined && (
        <FormHelperText id={helperTextId} variant="standard" error={error}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

type FormInputPhoneProps = Omit<FormInputTextProps, 'type'>;

export const FormInputPhone = ({
  id,
  label,
  value,
  updateValue,
  errorMessage,
  ...rest
}: FormInputPhoneProps) => {
  const phoneFormatter = new AsYouType();
  const formattedPhone = phoneFormatter.input(value ?? '');

  const showError = formattedPhone.length > 0 && !phoneFormatter.isValid();

  return (
    <FormInputText
      id={id}
      type="tel"
      label={label}
      value={formattedPhone}
      updateValue={updateValue}
      error={showError}
      errorMessage={showError ? errorMessage : undefined}
      placeHolder="+33 6 10 20 30 40"
      {...rest}
    />
  );
};

export const TextInput = styled(InputBase)`
  label + & {
    margin-top: 22px;
  }
  position: relative;
  border-radius: 8px;

  border: 1px solid ${colors.translucentWhite};
  padding: 10px 12px;
  transition: box-shadow border-color;
  &:focus-within {
    border-color: ${colors.primary};
    box-shadow: ${alpha(colors.primary, 0.25)} 0 0 0 0.2rem;
  }

  &.Mui-error,
  .Mui-error + & {
    border: 1px solid ${colors.manganeseRed};

    &:focus-within {
      border-color: ${colors.manganeseRed};
      box-shadow: ${alpha(colors.manganeseRed, 0.25)} 0 0 0 0.2rem;
    }
  }
`;

const StyledInputLabel = styled(InputLabel)<{
  $variant: FormInputTextProps['variant'];
}>`
  font-size: ${({ $variant }) =>
    $variant === 'inputWithIcon' ? fontSize.medium : fontSize.large};
`;

/**
 *  When using this component make sure you pass input={<TextInput />} to get the correct styling
 */
export const SelectInput = styled(Select<string>)`
  &:active {
    border-color: ${colors.primary};
    box-shadow: ${alpha(colors.primary, 0.25)} 0 0 0 0.2rem;
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  margin: 0;
  padding: 0 10px;
  top: 0;
  height: 100%;
  border-radius: unset;
  .MuiSvgIcon-root {
    color: ${colors.miracle};
    font-size: 20px;
  }
  &:hover {
    background-color: ${colors.translucentWhite};
  }
  &:focus {
    background-color: ${colors.moonWhite};
  }
`;
