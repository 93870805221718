import { parseDateTime } from '@internationalized/date';

export const parseDate = (value: Date | string | undefined | null) => {
  if (value == null) return value;
  const parsed = new Date(value);

  return isNaN(+parsed) ? undefined : parsed;
};

export const parseDateBeforeFilter = (dateBefore: string | undefined) => {
  if (dateBefore === '' || dateBefore === undefined) return undefined;

  try {
    const parsed = parseDateTime(dateBefore).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });

    return parsed.toString();
  } catch (error) {
    console.error('Error while parsing date before filter');

    return undefined;
  }
};

export const parseDateAfterFilter = (dateAfter: string | undefined) => {
  if (dateAfter === '' || dateAfter === undefined) return undefined;

  try {
    const parsed = parseDateTime(dateAfter).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return parsed.toString();
  } catch (error) {
    console.error('Error while parsing date after filter');

    return undefined;
  }
};

export function parseAndFormat(
  dateString: string | undefined | null,
  formatter: Intl.DateTimeFormat
) {
  const dateParsed = parseDate(dateString);

  return dateParsed != null ? formatter.format(dateParsed) : undefined;
}
