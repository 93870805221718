import useSWR from 'swr';
import queryString from 'query-string';
import { ApiError } from 'next/dist/server/api-utils';
import useSWRImmutable from 'swr/immutable';
import { Album } from '@/types/Album/Album';
import { AlbumCollection } from '@/types/Album/AlbumCollection';
import { WorflowStepType } from '@/types/Media/common';
import {
  GetTokenAsync,
  msApiDelete,
  msApiGet,
  msApiPatch,
  msApiPost,
  SWRMSApiKey,
} from '@/api/clients';
import { MSApiError } from '@/types/ApiError';
import { useAuth0Credentials } from '@/hooks/use-auth0-credentials';

interface AlbumsParams {
  name?: string;
  reference?: string;
  search?: string;
  createdAt?: 'desc' | 'asc';
  workflowType?: WorflowStepType;
  createdAfter?: string;
  createdBefore?: string;
  page: number;
  itemsPerPage: number;
}

/**
 * GET /customer/albums
 * List all albums
 */
export function useAlbums(params: AlbumsParams) {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const formattedParams = queryString.stringify(params, {
    arrayFormat: 'comma',
  });
  const apiUrl = `customer/albums?${formattedParams}`;

  return useSWR<AlbumCollection, MSApiError, SWRMSApiKey>(
    [apiUrl, workspaceId, userId],
    ([path, wsId]) => msApiGet(path, getTokenAsync, wsId),
    {
      revalidateOnFocus: false,
      errorRetryCount: 3,
    }
  );
}

/**
 * GET /customer/albums/{uuid}
 * Retrieve a specific Album by uuid
 */
export const useAlbum = (uuid: string) => {
  const { userId, workspaceId, getTokenAsync } = useAuth0Credentials();

  const apiUrl = `customer/albums/${uuid}`;

  const { data, error, mutate } = useSWR<Album, ApiError, SWRMSApiKey>(
    [apiUrl, workspaceId, userId],
    ([path, wsId]) => msApiGet(path, getTokenAsync, wsId),
    {
      revalidateOnFocus: false,
      errorRetryCount: 3,
    }
  );

  return {
    data,
    mutateAlbum: mutate,
    isLoading: !error && !data,
    isError: !!error,
  };
};

export type PatchAlbum = { name: string };

/**
 * PATCH /customer/albums/{uuid}
 * Update information of a specific albums
 */
export const updateAlbum = async (
  body: PatchAlbum,
  uuid: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  const apiUrl = `customer/albums/${uuid}`;

  return msApiPatch(apiUrl, getTokenAsync, workspaceId, { json: body });
};

/**
 * DELETE /customer/albums/{uuid}
 * Soft delete a specific album
 */
export async function deleteAlbum(
  uuid: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) {
  const apiUrl = `customer/albums/${uuid}`;

  return msApiDelete(apiUrl, getTokenAsync, workspaceId);
}

type CreateAlbumDTO = { name: string; reference: string };

/**
 * POST /customer/album
 * Create a new album with a randomly generated reference
 */
export const createAlbum = async (
  name: string,
  getTokenAsync: GetTokenAsync,
  workspaceId: string | null
) => {
  const apiUrl = 'customer/album';

  const body: CreateAlbumDTO = {
    name,
    reference: crypto.randomUUID(),
  };

  return msApiPost<Album>(apiUrl, getTokenAsync, workspaceId, { json: body });
};

export type PropertyDetailsDTO = {
  albumUuid: string;
  address: string | null;
  bedroomCount: number | null;
  coordinates: number | null;
  description: string | null;
  mandateNumber: string | null;
  roomCount: number | null;
  propertyType: 'house' | 'apartment';
  squareMeters: number | null;
  selectedTags: string[] | null;
};

/**
 * POST /customer/album/{albumUuid}/property-details
 * Update the property details for the given album
 */

export const updatePropertyDetails = async (
  albumUuid: string,
  body: Partial<PropertyDetailsDTO>,
  workspaceId: string | null,
  getTokenAsync: GetTokenAsync
) => {
  const apiUrl = `customer/album/${albumUuid}/property-details`;

  return msApiPost<PropertyDetailsDTO>(apiUrl, getTokenAsync, workspaceId, {
    json: body,
  });
};

/**
 * GET /customer/album/{albumUuid}/property-details
 * Retrieve the property details for the given album
 */
export const usePropertyDetails = (albumUuid: string) => {
  const { userId, getTokenAsync, workspaceId } = useAuth0Credentials();
  const apiUrl = `customer/album/${albumUuid}/property-details`;

  return useSWR<PropertyDetailsDTO, MSApiError, SWRMSApiKey>(
    [`property_details/${albumUuid}`, workspaceId, userId],
    () => msApiGet(apiUrl, getTokenAsync, workspaceId)
  );
};

/**
 * GET /customer/album/property-details/common-tags
 * Retrieve the common tags for the property details
 */
type CommonTagsDTO = {
  features: string[] | null;
};

export const useCommonTags = () => {
  const { workspaceId, userId, getTokenAsync } = useAuth0Credentials();

  const apiUrl = `customer/album/property-details/common-tags`;

  return useSWRImmutable<CommonTagsDTO, MSApiError, SWRMSApiKey>(
    [apiUrl, workspaceId, userId],
    () => msApiGet<CommonTagsDTO>(apiUrl, getTokenAsync)
  );
};
